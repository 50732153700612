<template>
  <!-- Error page-->
  <div class="misc-wrapper error-page">
    <b-link class="brand-logo">
      <img
        src="@/assets/images/logo/ford-logo.png"
        alt="logo"
      >
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Nie znaleziono strony
        </h2>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Powrót
        </b-button>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton } from 'bootstrap-vue'

import store from '@/store/index'

export default {
  components: {
    BLink,
    BButton,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
.error-page .brand-logo {
  display: block; margin: 4em auto; text-align: center;
}
</style>
